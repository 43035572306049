.model{
  background-color: #fff200;
  padding: 4.5rem 0 5.5rem 5.5rem;
  position: relative;
  margin: 7rem auto;
  max-width: 70rem;
  //min-height: 43rem;
  @include max(1000px){
    margin: 4.5rem auto;
  }
  @include mobile{
    padding: 22rem 2rem 3.5rem 2.5rem;
  }
  &__content{
    @include mobile{
      max-width: 24.5rem;
      margin: 0 auto;
    }
  }
  &__img{
    width: 26.7rem;
    height: 16.3rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 3.8rem;
    top: 12rem;
    @include mobile{
      width: 24.5rem;
      height: 15.9rem;
      top: 3.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  &__title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #383838;
    letter-spacing: .05em;
    @include mobile{
      font-size: 1.6rem;
    }
  }
  &__list{
    margin: 2.2rem 0 4.2rem 0;
    min-height: 15rem;
    @include mobile{
      min-height: 100%;
    }
    &-item{
      font-family: $DINNextLTPro-Light;
      font-weight: $light;
      font-size: 1.5rem;
      line-height: 2.4rem;
      text-transform: uppercase;
      color: #383838;
      letter-spacing: .05em;
      display: block;
      padding-left: 2.5rem;
      position: relative;
      margin-bottom: .6rem;
      @include mobile{
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &:before{
        content: '';
        position: absolute;
        top: .1rem;
        left: 0;
        height: 1.2rem;
        width: .6rem;
        background-color: #c3ba11;
      }
    }
  }
  &__btns-wrap{
    font-size: 0;
    @include mobile{
      width: 23.5rem;
    }
  }
}