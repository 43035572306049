.wrapper{
  position: relative;
  z-index: 0;
  padding-left: 8.5rem;
  @include max(1000px){
    padding-left: 2.8rem;
  }
  &--to-right{
    padding-left: 16.3rem;
    @include max(1000px){
      padding-left: 2.8rem;
    }
  }
  &--cart{
    border-bottom: .1rem solid #e5e5e5;
    margin-bottom: 3.5rem;
  }
}