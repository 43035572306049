.pagination{
  font-size: 0;
  margin-top: 2rem;
  &__next, &__prev, &__mid{
    width: 3rem;
    height: 3rem;
    border: .1rem solid #e5e5e5;
    display: inline-block;
    vertical-align: top;
    user-select: none;
  }
  &__next, &__prev{
    position: relative;
    transition: background-color 0.2s ease;
    &:hover{
      background-color: #383838;
      cursor: pointer;
      svg{
        fill: #fff;
      }
    }
    svg{
      transition: fill 0.2s ease; 
      width: .7rem;
      height: 1.1rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      fill: #383838;
      margin: auto;
    }
  }
  &__prev{
    border-right-color: transparent;
  }
  &__next{
    border-left-color: transparent;
  }
  &__mid{
	width:5rem;
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.4rem;
    line-height: 3rem;
    color: #383838;
    text-align: center;
	&__left, &__right{
		display:inline-block;		
	}
	&__left{		
		width:2rem;
		text-align:right;
		border-right:none;
	}
	&__right{
		width:2rem;
		text-align:left;
		border-left:none;
	} 
  }
}