.block{
  width: 31.18%;
  display: inline-block;
  vertical-align: top;
  margin-right: 3.23%;
  margin-bottom: 5.2rem;
  text-decoration: none;
  transform: translate(-5rem, -5rem);
  opacity: 0;
  &--show{
    transform: translate(0, 0);
    opacity: 1;
    transition: 0.5s ease transform, 0.3s ease opacity;
  }
  &:hover{
    .block__img-wrap{
      background-color: #d4d4d4;
    }
  }
  &:nth-child(3n+3){
    margin-right: 0;
  }
  @include max(1000px){
    width: 48.385%;
    margin-bottom: 4.5rem;
    &:nth-child(odd){
      margin-right: 3.23%;
    }
    &:nth-child(even){
      margin-right: 0;
    }
  }
  @include mobile{
    width: 100%;
    &:nth-child(odd){
      margin-right: 0;
    }
  }
  &--setups{
    width: 46.79%;
    font-size: 0;
    @include mobile{
      width: 100%;
      margin-bottom: 3.5rem;
    }
    .block__img-wrap{
      padding-bottom: 75.89%;
    }
    &:nth-child(odd){
      margin-right: 6.42%;
      @include mobile{
        margin-right: 0;
      }
    }
    &:nth-child(even){
      margin-right: 0;
    }
  }
  &--bigger{
    width: 46.73%;
    @include mobile{
      width: 100%;
    }
    &:nth-child(odd){
      margin-right: 6.54%;
      @include mobile{
        margin-right: 0;
      }
    }
    &:nth-child(even){
      margin-right: 0;
    }
  }
  &__img{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 100%;
  }
  &__img-wrap{
    width: 100%;
    padding-bottom: 51.11%;
    //background-color: #f9f9f9;
    background-color: #e7e7e7;
    position: relative;
    transition: 0.3s ease background-color;
  }
  &__title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #383838;
    letter-spacing: .05em;
    padding-left: 3rem;
    position: relative;
    display: block;
    margin-top: 2.4rem;
    min-height: 3.6rem;
    &:before{
      content: '';
      position: absolute;
      top: .4rem;
      height: 3.6rem;
      left: 0;
      width: 1.1rem;
      background-color: #fff200;
    }
  }
  &__left-section{
    width: 40.05%;
    display: inline-block;
    @include mobile{
      width: 100%;
    }
  }
  &__right-section{
    width: 59.95%;
    display: inline-block;
    vertical-align: top;
    padding-left: 4.7rem;
    position: relative;
    @include mobile{
      width: 100%;
      padding-left: 3rem;
      margin-top: 2.3rem;
    }
    &:before{
      content: '';
      width: 1.1rem;
      height: 3.6rem;
      background-color: #fff200;
      position: absolute;
      top: 0;
      left: 1.7rem;
      @include mobile{
        left: 0;
      }
    }
  }
  &__subtitle{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #383838;
    letter-spacing: .05em;
    margin-top: -.5rem;
    display: block;
    margin-bottom: .7rem;
    @include mobile{
      font-size: 1.8rem;
    }
  }
  &__list{
    padding: 0;
    &-item{
      font-family: $DINNextLTPro-Light;
      font-weight: $light;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #383838;
      letter-spacing: .05em;
      display: block;
      margin-bottom: .7rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}