.close{
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 2.3rem;
  right: 5.3rem;
  transition: 0.2s ease transform;
  &:hover{
    transform: rotate(90deg);
    cursor: pointer;
    &:before, &:after{
      background-color: #c3ba11;
    }
  }
  &:before, &:after{
    content: '';
    width: 2.2rem;
    height: .2rem;
    background-color: #383838;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: 0.2s ease background-color;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}