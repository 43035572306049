/*--- font weights ---*/
$thin: 100;
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

/*--- fonts ---*/
$DINNextLTPro-Regular: 'DINNextLTPro-Regular', 'DINNextCYR-Medium', sans-serif, helvetica;
$DINNextLTPro-Light: 'DINNextLTPro-Light', 'DINNextCYR-Light', sans-serif, helvetica;
$DINNextLTPro-Medium: 'DINNextLTPro-Medium', 'DINNextCYR-Medium', sans-serif, helvetica;
$DINNextLTPro-Bold: 'DINNextLTPro-Bold', 'DINNextCYR-Bold', sans-serif, helvetica;