.btn{
  font-family: $DINNextLTPro-Bold;
  font-weight: $bold;
  font-size: 1.2rem;
  line-height: 4.8rem;
  background-color: #383838;
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .2em;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease background-color;
  &:before{
    content: '';
    position: absolute;
    display: block;
    top: 5rem;
    width: 20rem;
    height: 5rem;
    background-color: #fff;
    transform: translate3d(-120%,-5rem,0) rotate3d(0,0,1,45deg);
    transition: all 1.8s cubic-bezier(0.19,1,.22,1);
  }
  &:hover{
    background-color: #1c1c1c;
    cursor: pointer;
    &:before{
      transform: translate3d(120%,-10rem,0) rotate3d(0,0,1,90deg);
      background-color: rgba(255,255,255,0.3);
    }
  }
  &--anim{
    background-color: #1c1c1c;
    cursor: pointer;
    &:before{
      transform: translate3d(120%,-10rem,0) rotate3d(0,0,1,90deg);
      background-color: rgba(255,255,255,0.3);
    }
  }
  &--home-banner{
    width: 22rem;
    margin-top: 3rem;
    @include max(1000px){
      width: 18rem;
      margin-top: 2rem;
    }
  }
  &--long-block{
    margin-top: 4.5rem;
    width: 26rem;
    @include mobile{
      width: 18rem;
      margin-top: 2.5rem;
    }
  }
  &--transparent-yellow{
    background-color: transparent;
    border: .1rem solid #c3ba11;
    color: #383838;
    line-height: 4.6rem;
    &:hover{
      color: #fff;
      background-color: #383838;
    }
  }
  &--transparent{
    background-color: transparent;
    border: .1rem solid #383838;
    color: #383838;
    line-height: 4.6rem;
    &:hover{
      color: #fff;
      background-color: #383838;
    }
  }
  &--model{
    width: 22rem;
    @include mobile{
      width: 100%;
    }
    &:first-child{
      margin-right: 1rem;
      @include mobile{
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  &--product{
    width: 100%;
    margin-bottom: 1.2rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &--yellow{
    color: #383838;
    background-color: #fff200;
    &:hover{
      color: #fff;
    }
  }
  &--cart{
    width: 17.5rem;
    text-align: left;
    padding-left: 6rem;
    //margin-right: 6rem;
    position: relative;
    float: right;
    margin-top: 1.5rem;
    @include mobile{
      float: left;
    }
    &:hover{
      .cart-svg{
        fill: #fff;
      }
    }
    .cart-svg{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2rem;
      margin: auto 0;
      width: 2.5rem;
      height: 2.4rem;
      fill: #383838;
      pointer-events: none;
    }
  }
  &--services{
    width: 22rem;
    margin-top: 2rem;
    @include max(1000px){
      width: 18rem;
    }
  }
  &--submit-cart{
    width: 22rem;
    margin-top: .3rem;
    float: right;
    @include mobile{
      width: 100%;
    }
  }
  &--modal-form{
    @include mobile{
      width: 100%;
    }
  }
  &--contacts{
    width: 22rem;
    margin-top: 2rem;
    @include max(1000px){
      width: 24.5rem;
    }
  }
}