/** {
  box-sizing: border-box;
}
*/
/* Create two equal columns that floats next to each other */
.column{
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after{
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column{
    width: 100%;
  }
}

#content-expandable{
  max-height: 240px;
  overflow: hidden;
  transition: max-height 0.7s;
}
#content-expandable.open {
  max-height: 2000px;
  transition: max-height 0.7s;
}

/*nuoroda show more*/
#show-more {
  width: 20%;
  height:20px;
  display: block;
  cursor: pointer;
  text-align: center;
  /*color: #000;*/
  font-size: 1.5rem;
  /* padding: 15px;*/
  margin: 20px auto 0px auto;
}