.search{
  max-width: 82rem;
  width: 90%;
  margin: 0 auto;
  .search-svg{
    width: 1.6rem;
    height: 1.6rem;
    fill: #383838;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    margin: auto 0;
    z-index: 1;
    pointer-events: none;
  }
  &__input{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.5rem;
    line-height: 5rem;
    height: 5rem;
    border: .1rem solid #9c9c9c;
    color: #9c9c9c;
    letter-spacing: .05em;
    width: 100%;
    padding: 0 2rem;
  }
  &__input-wrap{
    position: relative;
    margin: 4.7rem 0 6rem 0;
  }
  &__single{
    font-size: 0;
    position: relative;
    min-height: 13rem;
    margin-bottom: 6rem;
  }
  &__single-img{
    position: absolute;
    height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  &__single-left{
    //width: 15.85%;
    width: 25%;
    padding-bottom: 15.85%;
    background-color: #e7e7e7;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  &__single-right{
    display: inline-block;
    vertical-align: top;
    //width: 84.15%;
    width: 75%;
    padding-left: 3rem;
  }
  &__single-title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #383838;
    letter-spacing: .05em;
    margin-bottom: 2rem;
  }
  &__single-link{
    text-decoration: none;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &__single-link-text{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: .2em;
    color: #383838;
  }
  .arrow-right{
    width: 3.3rem;
    height: .6rem;
    fill: #383838;
    margin-left: 1.5rem;
  }
}