.header{
  background-color: #fff;
  height: 8.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &__category-title{
    //min-height: 4.8rem;
    min-height: 2rem;
    display: block;
    text-decoration: none;
  }
  &__burger{
    width: 2.5rem;
    height: 2.5rem;
    display: none;
    position: relative;
    margin: 3rem 2rem 0 1.5rem;
    @include max(1000px){
      display: inline-block;
    }
    &-top, &-mid, &-bottom{
      height: .2rem;
      background-color: #383838;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: auto 0;
      pointer-events: none;
      transition: 0.3s ease transform;
    }
    &-top{
      width: 2.2rem;
      top: -1.5rem;
    }
    &-mid{
      top: 0;
      width: 1.7rem;
    }
    &-bottom{
      width: 2rem;
      top: 1.5rem;
    }
    &--active{
      .header__burger-top{
        top: 0;
        transform: rotate(45deg);
      }
      .header__burger-mid{
        display: none;
      }
      .header__burger-bottom{
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
  &__logo{
    margin: 2.4rem 0 0 2.8rem;
    display: inline-block;
    &--hide{
      display: none;
    }
  }
  &__right-section{
    float: right;
    margin-right: 6rem;
    font-size: 0;
    @include max(1000px){
      margin-right: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &__nav{
    display: inline-block;
    vertical-align: top;
    margin-top: 3rem;
    @include max(1000px){
      display: none;
    }
    &-item{
      display: inline-block;
      margin-right: 2rem;
      position: relative;
      @include max(1080px){
        margin-right: 0;
      }
      &--hassubmenu{
        position: static;
        padding-bottom: 2.3rem;
        .header__nav-item-link{
          position: relative;
          &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent #f1f1f1 transparent;
            position: absolute;
            left: 0;
            right: 0;
            top: 5rem;
            margin: auto;
            opacity: 0;
            transition: 0.3s ease opacity;
          }
        }
      }
      &--setups-active{
        .header__nav-item-link{
          &:before{
            opacity: 1;
          }
        }
      }
      &:after{
        content: '';
        display: block;
        width: calc(100% - 2rem);
        height: .1rem;
        background-color: #383838;
        margin-left: 1rem;
        transform-origin: right center;
        transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: scale(0, 0);
      }
      &:hover{
        &:after{
          transform: scale(1,1);
          transform-origin: left center;
        }
      }
      &-link{
        text-decoration: none;
        font-size: 1.2rem;
        line-height: 1;
        font-family: $DINNextLTPro-Regular;
        font-weight: $normal;
        color: #383838;
        text-transform: uppercase;
        letter-spacing: .2em;
        display: block;
        padding: 1rem;
      }
    }
  }
  &__search-wrap{
    //width: 24rem;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 1.3rem 0;
    border-right: .1rem solid #e5e5e5;
    margin-top: 1.65rem;
    @include max(1000px){
      display: none;
    }
  }
  &__search-input{
    font-size: 1rem;
    line-height: 3rem;
    height: 3rem;
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #383838;
    border-bottom: .1rem solid #383838;
    margin-right: 1.8rem;
    width: 0;
    transition: 0.3s ease width;
	@include max(1000px){
	  line-height: 2rem;
	  height: 2rem;
	  margin-top: 1rem;
	}
    &--show{
      width: 16.4rem;
	  @include max(1000px){
		width: 6.4rem;
	  }
    }
  }
  &__localePicker-wrap{
	font-size:1.5rem;	
    position: relative;
    padding: 1.7rem 0;
    display: inline-block;
    vertical-align: top;
    margin: 1.8rem 0 0 1.8rem;	
	.from-control{
	  padding: 2rem;
	}
    @include max(1000px){
      margin-left: 0;
    }
	a{
	  display: inline-block; 	//jei naudoti svg
	  width:2.4rem; 			//jei naudoti svg
	  height: 2.1rem;				//jei naudoti svg (reikia IE)
	  text-decoration: none; //jei naudoti teksta
	  opacity:0.4;
	  &.active{
		font-weight:bold;	//jei naudoti teksta
		text-decoration: underline; //jei naudoti teksta
		opacity:1;
	  }
	}	  
  }
  &__cart-wrap{
    position: relative;
    padding: 1.2rem 1.8rem 1.8rem 0;
    display: inline-block;
    vertical-align: top;
    margin: 1.8rem 0 0 1.8rem;
    &--hide{
      display: none;
    }
    @include max(1000px){
      margin-left: 0;
    }
    &:hover{
      .header__cart-number-wrap{
        background-color: #f1e50b;
      }
    }
  }
  &__cart-number{
    font-size: 1.2rem;
    line-height: 1;
    height: 1.2rem;
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    &-wrap{
      width: 1.8rem;
      height: 1.8rem;
      background-color: #fff200;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      transition: 0.1s ease background-color, 0.2s ease transform;
      &--add{
        transform: scale(1.7);
      }
    }
  }
  &__menu{
    position: absolute;
    top: 8.5rem;
    left: 8.5rem;
    right: 0;
    z-index: 1;
    background-color: #f1f1f1;
    padding: 6.4rem 0 4.5rem 0;
    //display: none;
    transform: translateY(-2.5rem);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease transform, 0.3s ease opacity;
    max-height: 50.1rem;
    overflow: auto;
    &--show{
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }
    &-categ{
      //width: 110rem;
      width: 120rem;
      margin: 0 auto;	
	  padding-left: 0em;  	  
    }	
    &-categ-item{
      font-family: $DINNextLTPro-Bold;
      font-weight: $bold;
      font-size: 1.2rem;
      line-height: 2.4rem;
      letter-spacing: .2em;
      text-transform: uppercase;
      color: #383838;
      display: inline-block;
      //width: 13rem;
      width: 34rem;
      //margin-right: 5rem;
      margin-right: 4rem;
      vertical-align: top;
	 // &:nth-child(1){
	//	width: 32rem;
	 // }
	 // &:nth-child(2){
	//	width: 23rem;
	 // }
	 // &:nth-child(3){
	//	width: 20rem;
	//  }*/
      &:last-child{
        margin-right: 0;
      }
    }	
    &-categ-nav-item{
      font-family: $DINNextLTPro-Light;
      font-weight: $light;
      font-size: 1.5rem;
      letter-spacing: .05em;
      display: block;
      //margin-bottom: .5rem;
      margin-bottom: 1rem;
      width: auto;
      text-transform: none;
      &:last-child{
        margin-bottom: 0;
      }
      &-link{
        text-decoration: none;
        display: inline-block;
        &:hover{
          &:after{
            transform: scale(1, 1);
            transform-origin: left center;
          }
        }
        &:after{
          content: '';
          width: 100%;
          height: .1rem;
          background-color: #383838;
          display: block;
          transform-origin: right center;
          transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transform: scale(0, 0);
        }
      }
    }
    &-categ-item-nav{
      border-top: .1rem solid #e5e5e5;
      margin-top: 1.2rem;
      padding-top: 2rem;
	  padding-left: 0;
    }
  }
  &__menu ul{
	//padding-left: 0em;
  }
  &__menu li{
    line-height: 1.6em;
  }	
  .logo-svg{
    width: 16.1rem;
    height: 3.8rem;
    @include max(1000px){
      width: 13.9rem;
      height: 3.1rem;
    }
  }
  .logo-text-svg{
    width: 1.6rem;
    height: 28.5rem;
    position: absolute;
    top: 9.5rem;
    left: 2.8rem;
    pointer-events: none;
    transition: 0.3s ease opacity;
    &--hide{
      opacity: 0;
    }
    @include max(1000px){
      display: none;
    }
  }
  .search-svg{
    width: 1.6rem;
    height: 1.6rem;
    fill: #383838;
    margin: .6rem 4rem 0 0;
    display: inline-block;
    vertical-align: top;
    &:hover{
      cursor: pointer;
    }
	@include max(1000px){
      margin: .6rem 1rem 0 0;    
    }
  }
  .cart-svg{
    width: 2.7rem;
    height: 2.4rem;
    fill: #383838;
    @include mobile{
      width: 2.2rem;
      height: 2rem;
    }
  }
  &__menu-category-subcategory{
    border-top: .1rem solid #e5e5e5;
    border-bottom: .1rem solid #e5e5e5;
    padding: 1rem 0 0 1rem;
    display: none;
  }
  &__menu-hassubmenu{
    position: relative;
    display: block;
    &:before{
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: .7rem;
      right: -1.5rem;
      content: '';
      border-right: .1rem solid #4d4d4d;
      border-bottom: .1rem solid #4d4d4d;
      transform: rotate(45deg);
      transition: transform 0.2s ease;
    }
    &--active{
      &:before{
        transform: rotate(225deg);
      }
    }
  }
}

.nav-mobile{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  &__list{
    padding-top: 8.5rem;
    padding-left: 2.8rem;
  }
  &__list-item{
    display: block;
    background-color: #f1f1f1;
    border-bottom: .1rem solid #e5e5e5;
    &:last-child{
      border-bottom: none;
    }
  }
  &__list-item-link{
    font-family: $DINNextLTPro-Regular;
    font-weight: $normal;
    color: #383838;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: .2em;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    padding: 2.9rem 0 2.9rem 3rem;
  }
}