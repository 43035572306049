.section-title{
  font-family: $DINNextLTPro-Light;
  font-weight: $light;
  font-size: 3.6rem;
  line-height: 4.8rem;
  letter-spacing: .1em;
  color: #383838;
  margin: 3.3rem 0 3rem 0;
  @include max(1000px){
    font-size: 2.4rem;
    line-height: 3rem;
    margin: 0 0 2.5rem 0;
  }
  &--article{
    font-size: 3rem;
    line-height: 3.6rem;
    letter-spacing: .025em;
    padding: 0 0 4rem 0;
    margin: 0;
    position: relative;
    @include max(1000px){
      font-size: 2.4rem;
      line-height: 3rem;
      padding: 0 2rem 2rem 0;
    }
    //&:before{
    //  content: '';
    //  width: 5rem;
    //  height: 1.1rem;
    //  background-color: #fff200;
    //  position: absolute;
    //  bottom: -1.6rem;
    //  left: -9rem;
    //  @include max(1000px){
    //    display: none;
    //  }
    //}
  }
  &--news{
    @include max(1000px){
      padding-left: 3.3rem;
    }
  }
  &--smaller{
    font-size: 3rem;
    line-height: 3.6rem;
    letter-spacing: .025em;
    @include max(1000px){
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }
  &--product{
    margin: 3.1rem 0 3.3rem 0;
    line-height: 4.8rem;
    @include max(1000px){
      line-height: 3rem;
      margin: 0 0 2rem 0;
    }
  }
  &--setup{
    margin: 3.4rem 0 .9rem 0;
    line-height: 4.8rem;
    display: inline-block;
    @include max(1000px){
      line-height: 3rem;
    }
    @include mobile{
      margin-bottom: 0;
    }
  }
  &--cart{
    @include max(1000px){
      margin: 8.5rem 0 .9rem 0;
    }
  }
  &--success{
    margin: 3.9rem 0 3.6rem 0;
    @include max(1000px){
      margin: 1.5rem 0 1rem 0;
    }
  }
  &--contacts{
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: .1em;
    margin: 0;
    @include max(1000px){
      font-size: 2.4rem;
    }
  }
}