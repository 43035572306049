.blocks{
  font-size: 0;
  position: relative;
  &--home{
    padding: 8rem 8rem 1.5rem 8rem;
    @include max(1000px){
      padding: 3.8rem 2rem 2rem 0;
    }
    .blocks__rotated-title{
      transform: rotate(-90deg);
      transform-origin: top left;
      left: 0;
      top: 42rem;
      @include max(1000px){
        left: auto;
        top: auto;
        transform: rotate(0);
      }
    }
  }
  &--products{
    padding: 7.3rem 8rem 1rem 0;
    @include max(1000px){
      padding: 2.5rem 2rem 3rem 0;
    }
  }
  &--setup{
    padding: 5.5rem 16rem 2.3rem 0;
    min-height: 35rem;
    @include max(1000px){
      padding: 5rem 2rem 0 0;
    }
    .blocks__rotated-title{
      transform: rotate(90deg);
      transform-origin: top right;
      right: 8rem;
      top: 30rem;
      @include max(1000px){
        transform: rotate(0);
        right: auto;
        top: auto;
      }
    }
  }
  &__wrapper{
    width: 100%;
  }
  &__rotated-title{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 6rem;
    line-height: 1;
    color: #efefef;
    letter-spacing: .075em;
    position: absolute;
    pointer-events: none;
    @include max(1000px){
      position: relative;
      font-size: 3.6rem;
      letter-spacing: .075em;
      margin-bottom: 3.5rem;
    }
  }
}