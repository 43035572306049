.back{
  text-decoration: none;
  font-size: 0;
  padding: 1rem 1rem 1rem 0;
  display: inline-block;
  margin-bottom: 2.3rem;
  @include max(1000px){
    margin-bottom: 1.3rem;
  }
  &--mobile{
    display: none;
    @include max(1000px){
      display: inline-block;
      margin-top: 4rem;
      margin-bottom: 2rem;
      .back__arrow{
        &:before{
          border-top-color: #383838;
          border-left-color: #383838;
        }
        &:after{
          background-color: #383838;
        }
      }
      .back__text{
        color: #383838;
      }
    }
  }
  &:hover{
    .back__arrow{
      &:after{
        width: 2rem;
      }
    }
  }
  &__arrow{
    width: 4rem;
    height: .7rem;
    position: relative;
    display: inline-block;
    &:before{
      content: '';
      width: .5rem;
      height: .5rem;
      border-top: .1rem solid #fff;
      border-left: .1rem solid #fff;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: auto 0;
    }
    &:after{
      content: '';
      width: 100%;
      height: .1rem;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transition: width 0.2s ease;
    }
  }
  &__text{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .2em;
    color: #fff;
    display: inline-block;
    margin-left: 1.5rem;
  }
}