.pointers{
  margin: 4.5rem 0 2rem 0;
  display: none;
  @include mobile{
    display: block;
  }
  &--product{
    margin: 2.5rem;
    padding-right: 2.8rem;
  }
  .pointers-svg{
    width: 22.8rem;
    height: 2.9rem;
    fill: #a2a2a2;
    margin: 0 auto;
    display: block;
  }
}