.setup{
  &__container{
    overflow-x: auto;
  }
  &__wrap{
    padding-right: 6rem;
    width: 100%;
    min-width: 103.5rem;
    display: table;
    //border-top: .1rem solid #e5e5e5;
    //border-bottom: .1rem solid #e5e5e5;
  }
  &__wrap-top{
    //border-top: .1rem solid #e5e5e5;
    //border-bottom: .1rem solid #e5e5e5;
    display: table-row;
  }
  &__top-item{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.2rem;
    line-height: 1;
    color: #383838;
    text-transform: uppercase;
    letter-spacing: .025em;
    display: table-cell;
    vertical-align: bottom;
    padding: 2.5rem 0 2.1rem 0;
    border-top: .1rem solid #e5e5e5;
    border-bottom: .1rem solid #e5e5e5;
    &--setup{
      padding: .8rem 1.55% 2.1rem .9rem;
      &:nth-child(1){
        width: 30.98%;
      }
      //&:nth-child(2){
      //  width: 13.28%;
      //}
      &:nth-child(2){
        width: 50.61%;
      }
      &:nth-child(3){
        width: 10%;
      }
    }
    &--top{
      vertical-align: top;
    }
    &--filter{
      text-align: center;
      padding-right: 1.55%;
      .filter-name{
        padding-bottom: 1.5rem;
        position: relative;
        display: block;
      }
      .arrow-down, .arrow-up{
        &:hover{
          cursor: pointer;
        }
      }
      .arrow--hide{
        display: none;
      }
      .arrow-down{
        position: absolute;
        bottom: 0;
        right: 0;
        left: -1.2rem;
        margin: 0 auto;
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1rem .7rem 0 .7rem;
        border-color: #bbb transparent transparent transparent;
        transition: .2s border-color ease;
        &:hover{
          border-color: #383838 transparent transparent transparent;
        }
        &--center{
          left: 0;
          border-color: #383838 transparent transparent transparent;
        }
      }
      .arrow-up{
        content: '';
        position: absolute;
        bottom: 0;
        right: -1.2rem;
        left: 0;
        margin: 0 auto;
        z-index: 2;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 .7rem 1rem .7rem;
        border-color: transparent transparent #bbb transparent;
        transition: .2s border-color ease;
        &:hover{
          border-color: transparent transparent #383838 transparent;
        }
        &--center{
          right: 0;
          border-color: transparent transparent #383838 transparent;
        }
      }
      &:last-child{
        padding-right: 0;
        padding-left: .9rem;
        text-align: left;
        vertical-align: bottom;
        padding-bottom: 7.6rem;
      }
    }
    &--cart{
      padding-left: .8rem;
      padding-right: .8rem;
      &:nth-child(1){
        width: 28.98%;
      }
      &:nth-child(2){
        width: 34.78%;
      }
      &:nth-child(3){
        width: 12.56%;
      }
      &:nth-child(4){
        width: 12.27%;
		text-align:center;
      }
      &:nth-child(5){
        width: 11.41%;
      }
    }
  }
  &__row{
    padding: 1.7rem 0;
    border-bottom: .1rem solid #e5e5e5;
    transition: 0.2s ease background-color;
    display: table-row;
    &--cart{
      position: relative;
      overflow: hidden;
    }
    &:hover{
      background-color: #f5f5f5;
      .setup__text{
        max-height: 100%;
      }
      .setup__img-wrap{
        background-color: #f5f5f5;
      }
    }
  }
  &__row-item{
    display: table-cell;
    position: relative;
    vertical-align: top;
    padding: .8rem 1.55% .8rem .9rem;
    border-bottom: .1rem solid #e5e5e5;
    .setup__text{
      line-height: 3rem;
    }
    &--setup{
      &:nth-child(1){
        width: 30.98%;
        font-size: 0;
      }
      &:nth-child(2){
        width: 50.61%;
      }
      //&:nth-child(3){
      //  width: 37.33%;
      //}
      &:nth-child(3){
        width: 10%;
      }
    }&-link{
	  display: table-cell;
	  width:24px;
	  height:24px;
	  &--pdf{
	    background: url(../../assets/svg/icons/pdfMy.svg) no-repeat;
	  }
	  &--dxf{
	    background: url(../../assets/svg/icons/dxfMy.svg) no-repeat;
	  }
	  &--step{
	    background: url(../../assets/svg/icons/stepMy.svg) no-repeat;
	  }
	}	
    &--cart{
      &:nth-child(1){
        width: 28.98%;
        font-size: 0;
      }
      &:nth-child(2){
        width: 34.78%;
      }
      &:nth-child(3){
        width: 12.56%;
      }
      &:nth-child(4){
        width: 12.27%;
		padding-left:2rem;
      }
      &:nth-child(5){
        width: 11.41%;
      }
    }
  }
  &__img-wrap{
    --width: 7rem;
    width: 8rem;
    height: 6rem;
    background-color: #f9f9f9;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    transition: 0.2s ease background-color;
  }
  &__img{
    height: 100%;
    width: auto;
    max-width: 100%;
  }
  &__text{
    max-height: 3rem;
    display: block;
    overflow: hidden;
    @include mobile{
      max-height: 100%;
    }
    &--img{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 6rem);
      padding-left: .9rem;
    }
  }
  &__text, &__input{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #383838;
    letter-spacing: .025em;
  }
  &__input{
    width: 5rem;
    border: .1rem solid #e5e5e5;
    text-align: center;
    line-height: 2.8rem;
    height: 3rem;
    transition: 0.2s ease border-color;
    &:focus{
      border-color: #bbbbbb;
    }
    &--filter{
      text-align: left;
      line-height: 3.4rem;
      width: 100%;
      background-image: url('../svg/filter.svg');
      background-position: calc(100% - 1rem) center;
      background-repeat: no-repeat;
      background-size: 1.4rem 1.2rem;
      padding: 0 3rem 0 1rem;
      margin-top: 1rem;
    }
  }
  &__more-btn{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 1.2rem;
    line-height: 2.8rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .2em;
    width: 5.6rem;
    border: .1rem solid #383838;
    display: inline-block;
  }
  &__add-to-cart{
    width: 3rem;
    height: 3rem;
    background-color: #fff200;
    position: relative;
    transition: 0.2s ease background-color, 0.2s ease color;
    &:hover{
      cursor: pointer;
      background-color: #383838;
      .cart-svg{
        fill: #fff;
      }
    }
    .cart-svg{
      width: 1.6rem;
      height: 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &__delete{
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    transition: 0.2s ease transform;
    &:hover{
      transform: rotate(90deg) translate3d(0,0,0);
      cursor: pointer;
    }
    &:before, &:after{
      content: '';
      width: 1.4rem;
      height: .2rem;
      background-color: #ff0000;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  &__print-wrap{
    font-size: 0;
    display: inline-block;
    margin-bottom: 1.6rem;
    margin-top: 2.5rem;
    &:hover{
      cursor: pointer;
      .setup__print-text{
        color: #383838;
      }
      .setup__print-btn{
        border-color: #383838;
      }
      .print-svg{
        fill: #383838;
      }
    }
  }
  &__print-btn{
    width: 3rem;
    height: 3rem;
    border: .1rem solid #e5e5e5;
    position: relative;
    border-radius: .4rem;
    display: inline-block;
    vertical-align: top;
    transition: 0.2s ease border-color;
    .print-svg{
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      fill: #9c9c9c;
      transition: 0.2s ease fill;
    }
  }
  &__print-text{
    font-family: $DINNextLTPro-Regular;
    font-weight: $normal;
    font-size: 1.2rem;
    line-height: 3rem;
    color: #9c9c9c;
    text-transform: uppercase;
    letter-spacing: .025em;
    display: inline-block;
    vertical-align: top;
    margin-left: 1.5rem;
    transition: 0.2s ease color;
  }
  &__bottom{
    position: relative;
    margin-bottom: 4rem;
    min-width: 103.5rem;
  }
  &__bottom-left{
    width: 44.05%;
  }
  &__bottom-right{
    width: 20rem;
    position: absolute;
    top: 2.5rem;
    right: 6rem;
    z-index: 1;
  }
  &__subtotal, &__total{
    font-size: 0;
  }
  &__table-bottom{
    float: right;
    margin-right: 6rem;
  }
  &__total{
    margin-top: 1.4rem;
  }
  &__bottom-text{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.4rem;
    line-height: 1;
    color: #383838;
    text-transform: uppercase;
    letter-spacing: .025em;
    width: 7rem;
    display: inline-block;
    text-align: right;
  }
  &__total-num, &__subtotal-num{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    line-height: 1;
    color: #383838;
    letter-spacing: .025em;
    width: 13rem;
    display: inline-block;
    text-align: right;
  }
  &__subtotal-num{
    font-size: 1.6rem;
  }
  &__total-num{
    font-size: 2.4rem;
  }
  &__quantity-wrap{
    font-size: 0;
    width: 11rem;
	margin: auto;
	//margin-left:0;
    *{
      user-select: none;
    }
    .setup__input{
      display: inline-block;
      vertical-align: top;
      &:focus{
        border-color: #e5e5e5;
      }
    }
  }
  &__quantity-btn{
    display: inline-block;
    vertical-align: top;
    width: 3rem;
    height: 3rem;
    border: .1rem solid #e5e5e5;
    position: relative;
    //background-color: #fff200;
    transition: background-color 0.2s ease;
    &:hover{
      background-color: #383838;
      &:before, &:after{
        background-color: #fff;
      }
    }
    &:hover{
      cursor: pointer;
    }
    &--plus{
      border-left: transparent;
      &:before, &:after{
        content: '';
        width: 1.2rem;
        height: .1rem;
        background-color: #383838;
        pointer-events: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: background-color 0.2s ease;
      }
      &:after{
        transform: rotate(90deg) translateZ(0);
      }
    }
    &--minus{
      border-right: transparent;
      &:before{
        content: '';
        width: 1.2rem;
        height: .1rem;
        background-color: #383838;
        pointer-events: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}