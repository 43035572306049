.table{
  padding: 3rem;
  background-color: #999999;
  margin: 7rem auto;
  max-width: 70rem;
  &--setup{
    padding: 0;
    margin: 0;
  }
  @include mobile{
    overflow: auto;
    margin: 0 0 4.5rem 0;
  }
}