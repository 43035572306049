.sketch{
  height: 43rem;
  width: 100%;
  background-image: url('../images/sketech-patern.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  margin: 7rem auto;
  max-width: 70rem;
  @include mobile{
    margin: 4.5rem 0;
    height: 16.5rem;
  }
  &__img{
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
    @include mobile{
      height: 100%;
      padding: 2.5rem 0;
    }
  }
}