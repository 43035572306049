.banner{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4rem;
  height: 0;
  padding-bottom: 38.98%;
  position: relative;
  @include max(1000px){
    padding-bottom: 0;
    height: 34.5rem;
    margin-top: 8.5rem;
  }
  &--home{
    margin-top: 8.5rem;
    @include max(1000px){
      height: 46.5rem;
      .banner__text-content{
        @include max(1000px){
          left: 3rem;
          width: 85%;
        }
      }
    }
    .swiper-container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .swiper-container, .swiper-wrapper{
      height: 100%;
    }
    .swiper-slide{
      position: relative;
    }
    .swiper-pagination{
      display: none;
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%) translate3d(0,0,0);
      z-index: 2;
      @include max(1000px){
        display: block;
      }
    }
    .swiper-pagination-bullet{
      width: 1.2rem;
      height: 1.2rem;
      background-color: #bbbbbb;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.5rem;
      transition: transform 0.2 ease;
      &:last-child{
        margin-right: 0;
      }
      &-active{
        //width: 1.8rem;
        //height: 1.8rem;
        transform: scale(1.5);
        background-color: #f9ee14;
      }
    }
  }
  &__image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    }
  }
  &__text-content{
    position: absolute;
    top: 0;
    height: 100%;
    left: 8rem;
    display: flex;
    align-items: center;
    @include max(1000px){
      left: 1.8rem;
      width: 80%;
    }
  }
  &__text{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 3rem;
    line-height: 3.6rem;
    color: #fff;
	text-shadow: 1px 1px black;
    letter-spacing: .025em;
    margin: 0;
    @include max(1000px){
      font-size: 2.4rem;
      line-height: 3rem;
    }
    &--bigger{
      font-size: 3.6rem;
      line-height: 4.8rem;
      @include max(1000px){
        font-size: 2.4rem;
        line-height: 3.6rem;
      }
    }
  }
  &__btns-wrap{
    font-size: 0;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    @include max(1000px){
      display: none;
    }
  }
  &__btn{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    background-color: rgba(0,0,0,0.5);
    width: 33.33%;
    padding: 1.5rem;
    transition: 0.3s ease background-color, 0.3s ease color;
    border-right: .1rem solid #4b5351;
    &:hover{
      background-color: rgba(0,0,0,0.8);
    }
    &:last-child{
      border-right: none;
    }
    &--active{
      background-color: #fff200;
      color: #383838;
      &:hover{
        background-color: #fff200;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
}