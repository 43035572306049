.footer{
  font-family: $DINNextLTPro-Light;
  font-weight: $light;
  font-size: 1.2rem;
  letter-spacing: .05em;
  color: #383838;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
		
  border-top: .1rem solid #e5e5e5;
  padding: 2rem 0;
  margin-left: 2.8rem;
  .fa {font-size: 1.8rem; width: 4rem; text-align: center; padding:0;}
  .fa:hover {opacity: 0.7;}
  @include max(1000px){
    padding: 2.5rem 0 2.2rem 0;
  }
  &__left{
    float: left;
  }
  &__right{
    float: right;
	padding:0;
	margin:0;
	text-align:left;
  }
  &__copyright{    
    float: right;
    margin-top: 3rem;
	margin-right: 6rem;	//5+1
	//padding-right:1rem;
    @include max(1000px){
      margin-top: 0;
      line-height: 1.8rem;
      max-width: 22rem;
    }
  }
  &__list{
    float: right;
	padding:0;
    margin-right: 5rem;
    //margin-bottom: 1rem;
    @include max(1000px){
      float: none;
    }
	&-title{
		text-transform: uppercase;
		display: block;
        padding: 1rem 1rem 1rem 0rem;
        @include max(1000px){
          padding: 0;
          margin-bottom: 1.3rem;
        }
      }
   &-item{
      display: inline-block;
      margin-right: 2rem;
      @include max(1000px){
        display: inline-block;
        &:last-child{
          margin-bottom: 1.9rem;
        }
      }
      &:hover{
        &:after{
          transform-origin: left center;
          transform: scale(1, 1);
        }
      }
      &:after{
        content: '';
        display: block;
        width: calc(100% - 2rem);
        height: .1rem;
        background-color: #383838;
        margin-left: 1rem;
        transform-origin: right center;
        transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: scale(0, 0);
        @include max(1000px){
          display: none;
        }
      }
      &:last-child{
        margin-right: 0;
      }
      &-link{
		font-size: 1.2rem;
		line-height: 1; 
		text-transform: uppercase;
		text-decoration: none;		     
        display: block;
        padding: 1rem;
		max-width: 20rem;
        @include max(1000px){
          padding: 0;
          margin-bottom: 1.3rem;
        }
      }
    }
  }
}