.form-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 3;
  display: none;
  -webkit-overflow-scrolling: touch;
  @include mobile{
    overflow: auto;
  }
  &--show{
    display: flex;
    @include mobile{
      display: block;
    }
  }
  &__left-section{
    width: 30.15%;
    min-width: 38rem;
    display: flex;
    align-items: center;
    background-color: #e7e7e7;
    @include mobile{
      width: 100%;
      min-width: 100%;
      display: block;
      padding: 5.5rem 0 4rem 0;
      text-align: center;
    }
  }
  &__right-section{
    width: 69.85%;
    display: flex;
    align-items: center;
    @include mobile{
      width: 100%;
      min-width: 100%;
      display: block;
    }
    &--scroll{
      display: inline-block;
      vertical-align: top;
      overflow: auto;
      padding-bottom: 4rem;
      @include mobile{
        overflow: hidden;
        padding-bottom: 0;
      }
    }
  }
  &__close-wrap{
    height: 2.4rem;
    position: absolute;
    top: 3.5rem;
    right: 3rem;
    z-index: 1;
    @include mobile{
      top: 1.8rem;
      right: 1.8rem;
    }
    &:after{
      content: '';
      width: 100%;
      height: .1rem;
      background-color: #383838;
      display: block;
      margin-top: .5rem;
      transition: transform 0.2s ease;
      transform: scale(0,0);
    }
    &:hover{
      cursor: pointer;
      &:after{
        transform: scale(1,1);
      }
      .form-modal__close{
        transform: rotate(90deg);
      }
    }
  }
  &__close{
    width: 2.4rem;
    height: 2.4rem;
    position: relative;
    display: inline-block;
    vertical-align: top;
    transition: transform 0.2s ease;
    &:before, &:after{
      content: '';
      width: 2.2rem;
      height: .2rem;
      background-color: #383838;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:after{
      transform: rotate(45deg);
    }
    &:before{
      transform: rotate(-45deg);
    }
  }
  &__close-text{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .2em;
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;
    margin-top: .8rem;
    @include mobile{
      margin-right: .5rem;
    }
  }
  &__contact-name{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: .025em;
    color: #383838;
    text-transform: uppercase;
    display: block;
    margin-bottom: 2rem;
  }
  &__contact-wrap{
    max-width: 33.3rem;
    height: 42.1rem;
    margin: 0 auto;
    //padding-right: 3.3rem;
    @include mobile{
      height: auto;
      padding-right: 0;
      max-width: 100%;
      display: inline-block;
      text-align: left;
    }
  }
  &__contact-text{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: .1em;
    color: #383838;
    text-decoration: none;
    display: block;
    margin-bottom: .7rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__contact-right{
    display: inline-block;
    vertical-align: top;
    margin-left: 1.5rem;
  }
  &__contact-left{
    width: 7.2rem;
    height: 7.2rem;
    display: inline-block;
    vertical-align: top;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 3.3rem;
    @include mobile{
      margin-left: 0;
    }
  }
  &__title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 3.6rem;
    color: #383838;
    letter-spacing: .1em;
    position: relative;
    padding-left: 3.2rem;
    display: block;
    margin-bottom: 11.5rem;
    @include mobile{
      margin-bottom: 3rem;
      padding-right: 0;
    }
    &:before{
      content: '';
      width: 1.1rem;
      height: 3rem;
      background-color: #fff200;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}