.text-block{
  &--half{
    //width: 60.5rem;
    width: 55%;
    @include max(1000px){
      margin-top: 8.5rem;
      padding-right: 2rem;
      width: 100%;
    }
  }
  &--article{
    width: 78rem;
    margin: 0 auto;
    padding-right: 8rem;
    img{
      width: 100% !important;
    }
    .sketch{
      img{
        width: auto !important;
      }
    }
    @include max(1000px){
      width: 100%;
      padding-right: 0;
      p{
        padding-right: 2rem;
      }
    }
  } 
  &--setups{
    //width: calc(100% - 8rem);    --2018-08-21 Minexas
    //margin-top: 3.5rem;   
	width: 60%;
	margin: 3.5rem auto;
    @include max(1000px){
      width: 100%;
      padding-right: 2rem;
    }
	&--image{
		width:100%;
		text-align: center;
		position: relative;
		width: 90vw;
		left: calc(-40vw + 40%);
	}
	&--caption{
	  text-align: center;
	  padding-left:5%;
	  padding-right:5%;
	  line-height: 2rem;
	  font-size: 1.4rem;
	  margin:0;
	}
  }
  &--setups img{
	width:100%;
  }
  //&--setups h1,&--setups h2,&--setups h3,&--setups h4{
//	width: 60%;
//	margin: 0 auto 0 auto;
//  }
//  &--setups p{
//	width: 60%;
//	margin: 2.5rem auto 0 auto;
//  }
  &--product{
    width: calc(100% - 8rem);
    font-size: 0;
    position: relative;
    min-height: 50rem;
    @include max(1000px){
      min-height: 100%;
      width: 100%;
      padding-right: 2rem;
      margin-top: 8.5rem;
    }
  }
  &--about{
    margin-top: 7.5rem;
    position: relative;
    padding-bottom: 7rem;
    @include max(1000px){
      margin-top: 3.5rem;
      padding-bottom: 4rem;
      padding-left: 3.3rem;
    }
    &:before{
      content: '';
      width: 5rem;
      height: 1.1rem;
      background-color: #fff200;
      position: absolute;
      top: .4rem;
      left: -9rem;
      @include max(1000px){
        width: 1.1rem;
        height: 5rem;
        left: 0;
      }
    }
  }
  &--news{
    @include max(1000px){
      padding-left: 0;
    }
  }
  &--services{
    font-size: 0;
    padding-right: 6rem;
    display: flex;
    align-items: center;
    margin-top: 7.8rem;
    padding-bottom: 7.5rem;
    @include max(1000px){
      margin-top: 3rem;
      padding-right: 2rem;
      display: block;
    }
  }
  &__left-section{
    width: 46.79%;
    padding-right: 10rem;
    @include max(1000px){
      width: 100%;
      padding-right: 0;
    }
    &--services{
      width: 49.09%;
      display: inline-block;
      vertical-align: top;
      padding-right: 11.5rem;
      position: relative;
      @include max(1000px){
        width: 100%;
        padding-right: 0;
        padding-left: 3rem;
        margin-bottom: 3.5rem;
      }
      &:before{
        content: '';
        width: 5rem;
        height: 1.1rem;
        position: absolute;
        top: .5rem;
        left: -9rem;
        background-color: #fff200;
        @include max(1000px){
          width: 1.1rem;
          height: 5rem;
          left: 0;
        }
      }
    }
  }
  &__right-section{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 53.21%;
    height: 100%;
    @include max(1000px){
      width: 100%;
      display: none;
    }
    &--services{
      width: 50.91%;
      position: relative;
      top: auto;
      right: auto;
      min-height: 50rem;
      background-color: #fff200;
      vertical-align: top;
      display: flex;
      align-items: center;
      padding: 4rem 0;
      @include max(1000px){
        width: 100%;
        min-height: auto;
        display: block;
        padding: 4.5rem 1rem 4rem 3rem;
      }
    }
    .swiper-container{
      height: 100%;
      background-color: #e7e7e7;
    }
    .swiper-slide{
      display: flex;
      align-items: center;
    }
    .swiper-pagination{
      position: absolute;
      bottom: 2.8rem;
      right: 2.8rem;
      z-index: 2;
    }
    .swiper-pagination-bullet{
      width: 1rem;
      height: 1rem;
      position: relative;
      display: inline-block;
      margin-right: .8rem;
      &:hover{
        cursor: pointer;
      }
      &:last-child{
        margin-right: 0;
      }
      &:before{
        content: '';
        width: .6rem;
        height: .6rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
        background-color: #bbbbbb;
      }
    }
    .swiper-pagination-bullet-active{
      width: 2.2rem;
      height: 1rem;
      &:before{
        width: 1.8rem;
        background-color: #fff200;
      }
    }
  }
  &__text-wrap{
    margin-bottom: 4rem;
    @include mobile{
      margin-bottom: 2rem;
    }
  }
  &__btns-wrap{
    width: 26rem;
    @include mobile{
      width: 100%;
    }
  }
  &__slider-img{
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__bottom{
    font-size: 0;
    padding-right: 8rem;
    margin-top: 3.5rem;
    @include max(1000px){
      padding-right: 2rem;
      margin-top: 2rem;
    }
  }
  &__bottom-left{
    width: 44.33%;
    margin-right: 11.34%;
    display: inline-block;
    vertical-align: top;
    @include max(1000px){
      width: 100%;
      margin-right: 0;
    }
  }
  &__bottom-right{
    width: 44.33%;
    display: inline-block;
    vertical-align: top;
    @include max(1000px){
      width: 100%;
    }
  }
  &__services-title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #383838;
    letter-spacing: .05em;
    margin-bottom: 4.3rem;
    @include max(1000px){
      font-size: 1.6rem;
      margin-bottom: 2.5rem;
    }
  }
  &__services-list{
   &-item{
     font-family: $DINNextLTPro-Light;
     font-weight: $light;
     font-size: 1.5rem;
     line-height: 2.4rem;
     color: #383838;
     letter-spacing: .05em;
     display: block;
     position: relative;
     padding-left: 2.6rem;
     margin-bottom: 3rem;
     &:last-child{
       margin-bottom: 0;
     }
     &:before{
       content: '';
       position: absolute;
       top: .4rem;
       left: 0;
       width: .6rem;
       height: 1.8rem;
       background-color: #c3ba11;
     }
   }
  }
  &__right-section-services-wrap{
    max-width: 38rem;
    margin: 0 auto;
  }
  &__mobile-slider{
    height: 25.5rem;
    position: relative;
    display: none;
    background-color: #e8e8e8;
    margin-bottom: 2.5rem;
    @include max(1000px){
      display: block;
    }
    .swiper-container{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    .swiper-pagination{
      display: none;
      position: absolute;
      bottom: 1.4rem;
      right: 1.8rem;
      z-index: 2;
      @include max(1000px){
        display: block;
      }
    }
    .swiper-pagination-bullet{
      width: 1.2rem;
      height: 1.2rem;
      background-color: #bbbbbb;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.5rem;
      transition: transform 0.2 ease;
      &:last-child{
        margin-right: 0;
      }
      &-active{
        transform: scale(1.5);
        background-color: #f9ee14;
      }
    }
  }
}