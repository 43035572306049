html {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	font-size: 62.5%;
	// rems -> (1000 / design size(1260px))vw
	//font-size: 0.79365vw;
	//@include max(1000px){
	//	font-size: 62.5%;
	//}
}

body {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

main {
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	height: 100%;
	min-height: 100vh;
	-webkit-overflow-scrolling: touch;
}

ol, ul{
	//list-style-position: outside;
	//padding-left: 1.5rem;
	padding-left: 2.5rem;
	//padding-left: 200px;
}

p, li{
	font-family: $DINNextLTPro-Light;
	font-weight: $light;
	font-size: 1.5rem;
	line-height: 2.4rem;
	letter-spacing: .05em;
	color: #383838;
	@include max(1000px){
		font-size: 1.4rem;
	}
}

table{
	width: 100%;
	background-color: #999999;
	@include mobile{
		//width: 70rem;		//2018-08-21 Minexas
		width: 100%;
	}
	thead{
		background-color: #848484;
		text-align: left;
	}
	tbody{
		tr{
			th,td{
				//line-height: 4.7rem;
				line-height: 2.2rem;
			}
			&:last-child{
				td,th{
					border-bottom: none;
				}
			}
		}
	}
	tr{
		th, td{
			&:last-child{
				border-right: none;
			}
		}
	}
	td, th{
		font-size: 1.2rem;
		line-height: 4.8rem;
		//width: 33.33%;
		color: #fff;
		//text-transform: uppercase;
		letter-spacing: .2em;
		border-right: .1rem solid #7b7b7b;
		border-bottom: .1rem solid #7b7b7b;
		//padding: 0 2rem;
		padding: 1.5rem 2rem 1.5rem 2rem;
		position: relative;
		img{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
	}
	td{
		font-family: $DINNextLTPro-Regular;
		font-weight: $normal;
	}
	th{
		font-family: $DINNextLTPro-Medium;
		font-weight: $medium;
	}
}

p+p{
	margin-top: 2.5rem;
}

.clear{
	@include clear();
}

input, textarea{
	-webkit-appearance: none;
}

hr{
	height: .1rem;
	background: #ccc;
}

strong{
	font-family: $DINNextLTPro-Bold;
	font-weight: $bold;
}

h1,h2,h3,h4{
	font-family: $DINNextLTPro-Regular;
	font-weight: $normal;
	margin: 0;
	@include max(1000px){
		font-size: 2.4rem;
		line-height: 3.8rem;
	}
}

h1{
	font-size: 3.6rem;
	line-height: 4.8rem;
}

h2{
	font-size: 3rem;
	line-height: 3.6rem;
}

h3{
	font-size: 2.4rem;
	line-height: 3rem;
}

h4{
	font-size: 1.8rem;
	line-height: 2.4rem;
}

.oc-text-gray{
	color: #ccc !important;
}

.oc-text-bordered{
	text-decoration: underline !important;
}

.oc-text-spaced{
	letter-spacing: .1em !important;
}

.oc-text-uppercase{
	text-transform: uppercase !important;
}