.specifications{
  margin-top: 5rem;
  position: relative;
  &__btns-list{
	padding:0;
    background-color: #c9c9c9;
    font-size: 0;
    @include max(1000px){
      display: none;
      position: absolute;
      top: 5.8rem;
      left: 0;
      width: 100%;
      z-index: 2;
    }
    &--1{
      .specifications__btns-list-item{
        width: 100%;
      }
    }
    &--2{
      .specifications__btns-list-item{
        width: 50%;
        @include max(1000px){
          width: 100%;
        }
      }
    }
    &--3{
      .specifications__btns-list-item{
        width: 33.33%;
        @include max(1000px){
          width: 100%;
        }
      }
    }
    &--4{
      .specifications__btns-list-item{
        width: 25%;
        @include max(1000px){
          width: 100%;
        }
      }
    }
    &--5{
      .specifications__btns-list-item{
        width: 20%;
        @include max(1000px){
          width: 100%;
        }
      }
    }
    &-item{
      font-family: $DINNextLTPro-Light;
      font-weight: $light;
      font-size: 1.5rem;
      line-height: 6rem;
      letter-spacing: .2em;
      color: #383838;
      display: inline-block;
      text-align: center;
      border-right: .1rem solid #bfbfbf;
      @include max(1000px){
        font-family: $DINNextLTPro-Medium;
        font-weight: $medium;
        font-size: 1.2rem;
        line-height: 5.8rem;
        text-transform: uppercase;
        letter-spacing: .025em;
        background-color: #d5d5d5;
        padding: 0 2rem;
        text-align: left;
        border-right: none;
        border-top: .1rem solid #bfbfbf;;
      }
      &:hover{
        cursor: pointer;
      }
      span{
        position: relative;
        &:before{
          content: '';
          width: 2rem;
          height: .6rem;
          background-color: transparent;
          position: absolute;
          left: 0;
          bottom: 0;
          top: 0;
          margin: auto 0;
          @include max(1000px){
            display: none;
          }
        }
      }
      &:last-child{
        border-right: none;
      }
      &--active{
        background-color: #d5d5d5;
        span{
          padding-left: 4rem;
          @include max(1000px){
            padding-left: 0;
          }
          &:before{
            background-color: #fff200;
          }
        }
      }
    }
  }
  &__content{
    background-color: #efefef;
    .iframe-wrap{
      position: relative;
      //padding-bottom: 33.36%;
      padding-bottom: 56.23%;
      //background-color: red;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-wrap{
      //max-width: 67.5rem;
      //width: 90%;
      width: 59.32%;
      margin: 0 auto;
      padding: 7rem 0;
      @include max(1000px){
        width: 100%;
        padding: 4.5rem 2rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
      &--table{
        padding: 5rem;
        max-width: 100%;
        width: 100%;
      }
	  &-pdfLink{
		padding-left:5%;
		background: url(../../assets/svg/icons/pdfMy.svg) no-repeat;
	  } 
	   &-pdfUserManualLink{
		padding-left:5%;
		background: url(../../assets/svg/icons/pdfUserManualMy.svg) no-repeat;
	  } 
	  &-dxfLink{
		padding-left:5%;
		background: url(../../assets/svg/icons/dxfMy.svg) no-repeat;
	  } 
	  &-stepLink{
		padding-left:5%;
		background: url(../../assets/svg/icons/stepMy.svg) no-repeat;
	  } 
	  &-zipLink{
		padding-left:5%;
		background: url(../../assets/svg/icons/zipMy.svg) no-repeat;
	  } 
	  &-customTabImage{
		max-width:100%;
		margin: auto;
		display: block;
	} 
    }
	&-wrap th{
		text-align:center;
	}
    &-single{
      display: none;
      &--active{
        display: block;
      }
    }
	
  }
  &__btns-wrap{
    position: relative;
  }
  &__btn-current{
    display: none;
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.2rem;
    line-height: 5.8rem;
    text-transform: uppercase;
    letter-spacing: .025em;
    background-color: #d5d5d5;
    width: 100%;
    padding: 0 2rem;
    background-image: url(../svg/arrow-down-spec.svg);
    background-position: calc(100% - 2rem) center;
    background-repeat: no-repeat;
    background-size: 1.1rem .8rem;
    user-select: none;
    @include max(1000px){
      display: block;
    }
  }
}