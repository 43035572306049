.page-nav{
  margin-top: 8.5rem;
  padding-bottom: .3rem;
  border-bottom: 1px solid #e5e5e5;
  @include max(1000px){
    display: none;
  }
  .home-svg{
    width: 1.2rem;
    height: 1.2rem;
    fill: #e5e5e5;
    margin-right: 1rem;
  }
  .home-arrow-svg{
    width: 2rem;
    height: .7rem;
    fill: #e5e5e5;
    margin: 0 1rem;
  }
  &__item{
    font-family: $DINNextLTPro-Regular;
    font-weight: $normal;
    font-size: 1.2rem;
    line-height: 1;
    color: #9c9c9c;
    text-transform: uppercase;
    letter-spacing: .025em;
    text-decoration: none;
    padding: 1rem;
    display: inline-block;
  }
}