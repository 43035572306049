.success{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: none;
  align-items: center;
  background-color: #fff;
  &--show{
    display: flex;
  }
  &__wrap{
    max-width: 49.5rem;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  &__icon-wrap{
    width: 7.8rem;
    height: 7.8rem;
    border: .1rem solid #fff200;
    position: relative;
    margin: 0 auto;
    .success-svg{
      width: 2rem;
      height: 2.6rem;
      fill: #fff200;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &__btn{
    font-family: $DINNextLTPro-Regular;
    font-weight: $normal;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: .2em;
    color: #383838;
    text-decoration: none;
    display: inline-block;
    margin-top: 5.6rem;
    @include max(1000px){
      margin-top: 3.7rem;
    }
    &:hover{
      &:after{
        transform: scale(0, 0);
      }
    }
    &:after{
      content: '';
      display: block;
      width: 100%;
      height: .1rem;
      background-color: #383838;
      margin-top: .8rem;
      transition: 0.2s ease transform;
    }
  }
}