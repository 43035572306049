.form{
  font-size: 0;
  &--cart{
    width: 84.375%;
    max-width: 76rem;
    margin: 0 auto;
    padding-bottom: 7.5rem;
    @include mobile{
      padding-bottom: 4.5rem;
    }
  }
  &--form-modal{
    max-width: 76rem;
    width: 90%;
    margin: 0 auto;
    @include mobile{
      width: 84.375%;
      padding: 3.5rem 0;
    }
  }
  &__input{
    width: 100%;
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.2rem;
    line-height: 5.2rem;
    height: 5.2rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    border: .1rem solid #e5e5e5;
    color: #383838;
    padding: 0 2rem;
    transition: 0.2s ease border-color, 0.2s ease color;
    &:focus{
      border-color: #bbbbbb;
    }
  }
  &__error{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: .1em;
    color: #ff0000;
    position: absolute;
    bottom: -1.65rem;
    left: 0;
    z-index: 0;
    display: none;
  }
  &__input-row{
    position: relative;
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;
    &--half{
      width: 49%;
      @include max(1000px){
        width: 100%;
      }
      &:nth-child(odd){
        margin-right: 2%;
        @include max(1000px){
          margin-right: 0;
        }
      }
    }
    &--textarea{
      .form__input{
        resize: none;
        height: 15rem;
        line-height: 3.6rem;
        padding-top: .6rem;
      }
    }
    &--error{
      .form__error{
        display: block;
      }
      .form__input{
        border-color: #ff0000;
        color: #ff0000;
        @include placeholder(){
          color: #ff0000;
        }
      }
    }
  }
  &__submit{
    width: 100%;
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    font-size: 1.2rem;
    line-height: 4.8rem;
    background-color: #383838;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .2em;
  }
  &__title{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 3.6rem;
    color: #383838;
    letter-spacing: .1em;
    display: block;
    margin-bottom: 1.6rem;
    @include mobile{
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
    &--modal{
      span{
        font-family: $DINNextLTPro-Light;
        font-weight: $light;
        font-size: 1.4rem;
        line-height: 2.4rem;
        letter-spacing: .05em;
        @include mobile{
          display: block;
        }
      }
    }
    &--modal-second{
      margin-top: 2.5rem;
      @include mobile{
        margin-top: 1rem;
      }
    }
  }
  &__product-title{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 3rem;
    line-height: 3.6rem;
    color: #383838;
    letter-spacing: .025em;
    max-width: 38rem;
    text-align: center;
    margin: 11.5rem auto 5.8rem auto;
    display: block;
    @include mobile{
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0 auto 4rem auto;
    }
  }
}