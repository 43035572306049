@font-face {
  font-family: 'DINNextLTPro-Regular';
  src: url('../fonts/DINNextLTPro-Regular.eot');
  src: url('../fonts/DINNextLTPro-Regular.ttf') format('truetype'),
       url('../fonts/DINNextLTPro-Regular.woff') format('woff');
  font-weight: $normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTPro-Light';
  src: url('../fonts/DINNextLTPro-Light.eot');
  src: url('../fonts/DINNextLTPro-Light.ttf') format('truetype'),
  url('../fonts/DINNextLTPro-Light.woff') format('woff');
  font-weight: $light;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTPro-Medium';
  src: url('../fonts/DINNextLTPro-Medium.eot');
  src: url('../fonts/DINNextLTPro-Medium.ttf') format('truetype'),
  url('../fonts/DINNextLTPro-Medium.woff') format('woff');
  font-weight: $medium;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTPro-Bold';
  src: url('../fonts/DINNextLTPro-Bold.eot');
  src: url('../fonts/DINNextLTPro-Bold.ttf') format('truetype'),
  url('../fonts/DINNextLTPro-Bold.woff') format('woff');
  font-weight: $bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextCYR-Light';
  src: url('../fonts/DINNextCYR-Light.eot');
  src: url('../fonts/DINNextCYR-Light.ttf') format('truetype'),
  url('../fonts/DINNextCYR-Light.woff') format('woff');
  font-weight: $light;
  font-style: normal;
}
@font-face {
  font-family: 'DINNextCYR-Medium';
  src: url('../fonts/DINNextCYR-Medium.eot');
  src: url('../fonts/DINNextCYR-Medium.ttf') format('truetype'),
  url('../fonts/DINNextCYR-Medium.woff') format('woff');
  font-weight: $medium;
  font-style: normal;
}
@font-face {
  font-family: 'DINNextCYR-Bold';
  src: url('../fonts/DINNextCYR-Bold.eot');
  src: url('../fonts/DINNextCYR-Bold.ttf') format('truetype'),
  url('../fonts/DINNextCYR-Bold.woff') format('woff');
  font-weight: $bold;
  font-style: normal;
}