.long-block{
  //min-height: 32rem;
  font-size: 0;
  background-color: #e7e7e7;
  margin-bottom: 3.5rem;
  position: relative;
  @include mobile{
    //position: relative;
    //height: auto;
  }
  &:last-child{
    margin-bottom: 7.5rem;
  }
  &__left-section{
    width: 50%;
    height: 100%;
    @include mobile{
      width: 100%;
      //height: auto;
      padding: 18.5rem 2rem 3rem 2rem;
      //margin-top: 14.5rem;
    }
  }
  &__right-section{
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    //display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include mobile{
      width: 100%;
      height: 14.5rem;
      //position: absolute;
      //top: 0;
      //left: 0;
    }
  }
  &__text-wrap{
    min-height: 32rem;
    //width: 33.5rem;
	width: 80%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 5rem 0;
	//padding-bottom: 4rem;
    @include mobile{
      width: 100%;
      min-height: auto;
      display: block;
      padding: 0;
    }
  }
  &__title{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    font-size: 3.6rem;
    line-height: 4.8rem;
    color: #383838;
    letter-spacing: .05em;
    @include mobile{
      font-size: 2.4rem;
      line-height: 3rem;
      letter-spacing: .1em;
    }
  }
  &__description{
    font-family: $DINNextLTPro-Light;
    //font-weight: $light;
   // font-size: 1.8rem;
    //line-height: 2,4rem;
    color: #383838;
    //letter-spacing: .05em;
    @include mobile{
      font-size: 1,2rem;
      line-height: 1,8rem;
      //letter-spacing: .1em;
    }
  }
  &__date{
	//font-family: $DINNextLTPro-Light;
    //float:right;
	//color: #383838;
	//font-size:1.4rem;
	margin-top: 1rem;
  }
}