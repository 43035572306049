*, *:before, *:after{
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  outline: none;
  border: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  padding: 0;
  margin: 0;
}