.contacts{
  font-size: 0;
  display: flex;
  margin-top: 4rem;
  margin-bottom: 5rem;
  @include max(1000px){
    margin-top: 8.5rem;
  }
  @include mobile{
    display: block;
  }
  .fa {padding: 5px; font-size: 16px; width: 35px; text-align: center; text-decoration: none; margin: 0.1px;}
  .fa:hover {opacity: 0.7;}
  &__left-section{
    background-color: #f5f5f5;
    width: 36.36%;
    display: flex;
    //align-items: center;
    padding-top: 5rem;
    min-height: 60rem;
    @include mobile{
      width: 100%;
      display: block;
      min-height: auto;
    }
    &-wrap{
      max-width: 23rem;
      margin: 0 auto;
      padding-right: 1rem;
      @include max(1000px){
        width: 81.03%;
        max-width: 100%;
        padding: 3.5rem 0;
      }
    }
	&-list{
	  float: left;
	  padding: 0rem;
	  margin-right: 0rem;
	  //margin-bottom: 1rem;
	  @include max(1000px){
		float: none;
       }
	  
		&-item{
		  display: inline-block;
		  margin-right: 0.8rem;
          @include max(1000px){
            display: block;
			}
		}
	}
  }  
  &__right-section{
    background-color: #e7e7e7;
    width: 63.64%;
    display: inline-block;
    vertical-align: top;
    min-height: 60rem;
    padding: 5rem 0 1.5rem 0;
    @include mobile{
      width: 100%;
      display: block;
      min-height: auto;
    }
    &-wrap{
      /* max-width: 52rem; */
      max-width: 78rem;
      width: 90%;
      margin: 0 auto;
      @include mobile{
        width: 81.03%;
        max-width: 100%;
      }
    }
  }
  &__mid-wrap{
    margin: 3.9rem 0 3rem 0;
    @include max(1000px){
      margin: 3.5rem 0 2.5rem 0;
    }
    &:before, &:after{
      content: '';
      width: 3.9rem;
      height: .1rem;
      background-color: #d5d5d5;
      display: block;
    }
    &:before{
      margin-bottom: 4.3rem;
      @include max(1000px){
        margin-bottom: 2.5rem;
      }
    }
    &:after{
      margin-top: 3rem;
      @include max(1000px){
        margin-bottom: 3rem;
      }
    }
  }
  &__mail, &__mid-text{
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #383838;
    display: block;
    @include max(1000px){
      font-size: 1.4rem;
    }
    a{
      text-decoration: none;
    }
  }
  &__mail{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    letter-spacing: .1em;
    margin-bottom: 1.9rem;
    text-decoration: none;
  }
  &__mid-text{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    letter-spacing: .1em;
  }
  &__single{
    display: inline-block;
    vertical-align: top;
    /* width: 43.26%; */
    width: 28.84%;
    margin-bottom: 3.5rem;
    @include mobile{
      margin-bottom: 2.8rem;
    }
    @include max(600px){
      width: 100%;
    }
    /*&:nth-child(odd){
      margin-right: 13.48%;
      @include max(600px){
        margin-right: 0;
      }
    }*/
  }
  &__single-title, &__name, &__single-text{
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: block;
    a{
      text-decoration: none;
    }
  }
  &__single-title{
    font-family: $DINNextLTPro-Bold;
    font-weight: $bold;
    letter-spacing: .025em;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }
  &__single-img{
    width: 14.4rem;
    height: 14.4rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__name{
    font-family: $DINNextLTPro-Medium;
    font-weight: $medium;
    letter-spacing: .1em;
    margin: 2rem 0 1.1rem 0;
  }
  &__single-text{
    font-family: $DINNextLTPro-Light;
    font-weight: $light;
    letter-spacing: .05em;
    word-break: inherit;
  }
}