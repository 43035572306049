.map{
  padding-bottom: 47.52%;
  position: relative;
  margin-bottom: 7rem;
  @include mobile{
    padding-bottom: 120%;
  }
  #map{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}